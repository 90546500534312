import React from "react"

import SEO from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

import Dev from "../../features/Dev"

const IndexPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="alvyApp - Dev Resources" />
      <Dev />
    </PageWrapper>
  )
}

export default IndexPage
