import React from "react"

import { Heading1, Heading2 } from "../../components/common"

const Link = ({ title, href, customClass = "", ...otherPops }) => {
  const baseClass = `no-underline text-gray-700`
  if (href) {
    return (
      <a
        href={href}
        className={`${baseClass} cursor-pointer hover:text-gray-900 hover:underline`}
        {...otherPops}
      >
        {title}
      </a>
    )
  } else {
    return <span className={`${baseClass}`}>{title}</span>
  }
}

const Dev = () => {
  return (
    <div>
      <Heading1>Dev Resources</Heading1>
      <Heading2>Open Beta</Heading2>
      <ul>
        <li>
          <Link
            title="Android - Prod"
            href="https://play.google.com/apps/testing/com.alvyapp"
          />
        </li>
        <li>
          <Link
            title="iOS - Prod"
            href="https://testflight.apple.com/join/gcOV2NHQ"
          />
        </li>
      </ul>
      <Heading2>Internal Track - Android</Heading2>
      <ul>
        <li>
          <Link
            title="Prod"
            href="https://play.google.com/apps/internaltest/4697644947509331937"
          />
        </li>
        <li>
          <Link
            title="Staging"
            href="https://play.google.com/apps/internaltest/4698150280518423082"
          />
        </li>
        <li>
          <Link
            title="Dev"
            href="https://play.google.com/apps/internaltest/4698052228250171694"
          />
        </li>
      </ul>
      <Heading2>Share Links</Heading2>
      <ul>
        <li>
          <Link
            title="Share Test 1"
            href="https://link.alvyapp.com/NjwedUkUv9WC4A878"
          />
        </li>
      </ul>
      {/* <Heading2>Dynamic Links</Heading2>
      <ul>
        <li>
          <Link title="DevLink Test" href="https://devlink.alvyapp.com/6SuK" />
        </li>
        <li>
          <Link
            title="Dev - Course Link - G"
            href="https://devlink.alvyapp.com/xj4GJ3P5CFiw7QSb9"
          />
        </li>
        <li>
          <Link
            title="Dev - Course Link - F"
            href="https://devlink.alvyapp.com/jpKLnehHBPL6UdYP7"
          />
        </li>
        <li>
          <Link
            title="Dev - Course Link - BMM"
            href="https://devlink.alvyapp.com/3C962M5PNBnD1RDG9"
          />
        </li>
        <li>
          <Link
            title="StagingLink Test"
            href="https://staginglink.alvyapp.com/https://staginglink.alvyapp.com/3tgzFZ9gwGopUanK8"
          />
        </li>
        <li>
          <Link
            title="Share Test 1"
            href="https://link.alvyapp.com/NjwedUkUv9WC4A878"
          />
        </li>
      </ul> */}
    </div>
  )
}

export default Dev
